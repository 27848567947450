<template>
  <div class="ak-text container-layout-custom" style="max-width: 1980px;">
    <div v-if="this.isIPRestricted" class="q-pa-xl full-width">
      <q-banner rounded class="bg-warning">
        <div class="text-h6">Sorry, your IP address is not one of the permitted IP addresses for your company. Please access this training on a verified company computer.</div>
      </q-banner>
    </div>
    <div class="q-pa-xl full-width" v-else>
      <div v-if="this.adminMode" class="row q-mb-lg">
        <q-banner dense rounded class="bg-warning col-sm-8 col-xs-12">
          <div class="text-subtitle1 text-primaryText">You are currently viewing this module in Admin Mode. Module Restriction is disabled while in this mode.</div>
        </q-banner>
      </div>
      <div class="row q-mb-lg" v-if="moduleIsComplete">
        <q-banner dense rounded inline-actions class="bg-positive col-sm-8 col-xs-12">
          <template v-slot:avatar>
            <q-icon name="assignment_turned_in" color="primaryText" />
          </template>

          <div v-if="module.assigned && module.assignment && !module.assignment.completed_at" class="text-subtitle1 text-primaryText">Congratulations! You have completed this module assignment.</div>
          <div v-else-if="module.assigned && module.assignment && module.assignment.completed_at" class="text-subtitle1 text-primaryText">Congratulations! You completed this module assignment on {{ formatDate(module.assignment.completed_at) }}.</div>
          <div v-else-if="module.hasCompleted && module.firstCompletedDate" class="text-subtitle1 text-primaryText">You completed this module on {{ formatDate(module.firstCompletedDate) }}</div>
          <div v-else class="text-subtitle1 text-primaryText">You have completed this module</div>

          <template v-slot:action>
            <q-btn v-if="module.settings.certificate !== '0' && module.settings.certificate !== 0" @click="downloadCert" flat label="Download Certificate" />
          </template>
        </q-banner>
      </div>
      <div class="row q-mb-lg" v-else-if="module.assigned && module.assignment && !module.assignment.completed_at">
        <q-banner dense rounded class="bg-warning text-primaryText col-sm-8 col-xs-12">
          <template v-slot:avatar>
            <q-icon name="schedule" color="primaryText" />
          </template>
          <div v-if="module.assignment.due_date" class="text-subtitle1 text-primaryText">This module is due on {{ formatDate(module.assignment.due_date) }}</div>
          <div v-else  class="text-subtitle1 text-primaryText">This module is currently assigned to you</div>
        </q-banner>
      </div>
      <div class="row justify-between">
        <div class="col-sm-8 col-xs-12">
          <div v-if="this.$isAllowed(['manage-modules']) && this.$isSameCompany(module.customer_uid)" class="row q-ml-sm q-mb-sm float-right">
            <q-btn color="primary" dense icon="settings" size="sm">
              <q-menu transition-show="jump-down" transition-hide="jump-up" self="top right" class="bg-lmsBackground">
                <q-list style="min-width: 100px" class="bg-lmsBackground primaryText-text">
                  <q-item>
                    <q-toggle v-model="adminMode" label="Admin Mode" left-label />
                  </q-item>
                  <q-item clickable v-close-popup @click="$router.push({ name: 'manageModulesEdit', params: { uid: module.uid } }).catch(() => {})">
                    <q-item-section>Edit Module</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="$router.push({ name: 'manageModulesImport', params: { uid: module.uid } }).catch(() => {})">
                    <q-item-section>Import Module</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>

          <div v-if="displayBanner || !currentAsset" class="row">
            <q-img :src="module.banner_path" contain/>
          </div>

          <div v-else-if="currentAsset.extension === 'embedded'" class="row embedded" ref="embedded">
            <q-resize-observer @resize="embeddedResize" />
            <iframe :width="embeddedWidth" :height="embeddedHeight" :src="currentAsset.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>

          <div v-else class="row" id="videoPlayerWrapper">
            <VideoPlayer :asset="currentAsset" :key="currentAsset.uid" v-bind:module.sync="module" @updateAsset="updateAsset" />
          </div>

          <div class="row">
            <div class="text-h6 col-12">{{ module.title }}</div>
            <div class="text-subtitle1 col-12">{{ module.description }}</div>
          </div>

        </div>
        <div class="col-sm-3">
          <AssetList v-if="assetsAreReady" :assets="moduleAssets" :module="module" @selectAsset="selectAsset" @updateAsset="updateAsset" @allAssetsComplete="completeModule" />
        </div>
      </div>
      <div class="row full-width">
        <q-tabs v-model="moduleTab" align="justify" class="q-mt-xl col-sm-8 col-xs-12">
          <q-tab name="discussion" label="Discussion" />
          <q-tab name="similar" label="Similar Videos" />
        </q-tabs>
        <div class="col-sm-8 col-xs-12">
          <q-separator />
        </div>
      </div>

      <div class="row full-width">
        <q-tab-panels v-model="moduleTab" class="col-sm-8 col-xs-12 bg-lmsBackground">
          <q-tab-panel name="discussion">
            <module-comments :moduleUid="module.uid" :discussions="module.discussions" v-if="$store.getters['auth/company'].uid === module.customer_uid && module.settings.comments"></module-comments>
            <div v-else>Discussions are not enabled for this module</div>
          </q-tab-panel>
          <q-tab-panel name="similar">
            <similar-videos :module="module" :categoryUid="module.category ? module.category.uid : ''"></similar-videos>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>

    <q-dialog v-model="takeQuiz" persistent full-width full-height>
      <module-quiz :takeQuiz="takeQuiz" :quiz="loadedQuiz" @quizPassed="updateAsset"></module-quiz>
    </q-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import JsPDF from 'jspdf'
import ModuleQuiz from 'components/ModuleQuiz'
import ModuleComments from 'components/ModuleComments'
import SimilarVideos from 'components/SimilarVideos'
import { MinionPro } from 'assets/fonts/MinionPro/MinionPro-Regular-normal.js'
import { AvenirNext } from 'assets/fonts/AvenirNext/AvenirNext-Medium-normal.js'
import AssetList from 'components/module/AssetList'
import VideoPlayer from 'components/module/VideoPlayer'
import { date } from 'quasar'

export default {
  props: ['moduleId'],
  name: 'ModulePage',
  components: { AssetList, ModuleQuiz, ModuleComments, SimilarVideos, VideoPlayer },
  data () {
    return {
      assetsAreReady: false,
      currentAsset: null,
      loadingVideo: false,
      moduleIsComplete: false,
      moduleTab: 'discussion',
      moduleAssets: [],
      takeQuiz: false,
      loadedQuiz: {},
      accessList: [],
      module: {
        uid: null,
        quiz_passed: false,
        settings: {
          bypassIpRestrictions: false
        },
        hasRestrictions: false
      },
      adminMode: false,
      embeddedSize: { width: 0, height: 0 }
    }
  },
  computed: {
    isIPRestricted () {
      if (this.module.settings.bypassIpRestrictions) return false
      if (this.accessList.length === 0) return false
      if (this.$store.getters['auth/user'].is_exempt === '1') return false
      if (this.accessList.every(accessItem => !accessItem.settings.enforce)) return false
      return this.accessList.reduce(function (bRestricted, accessItem) {
        if (!accessItem.settings.enforce) return bRestricted
        if (accessItem.is_match) return false
        if (!bRestricted) return false
        return true
      }, true)
    },
    completedObject () {
      return this.$store.getters['assignments/completed'].find(assignment => assignment.assignable_uid === this.module.uid)
    },
    displayBanner () {
      if (!this.currentAsset) return true

      if (this.currentAsset.extension === 'mp4' || this.currentAsset.extension === 'qt' || this.currentAsset.extension === 'mov' || this.currentAsset.extension === 'embedded') {
        return false
      } else {
        return true
      }
    },
    embeddedWidth () {
      return this.embeddedSize.width
    },
    embeddedHeight () {
      return this.embeddedSize.width / 1.77778
    }
  },
  methods: {
    embeddedResize (size) {
      this.embeddedSize = size
    },
    formatDate (pDate) {
      return date.formatDate(pDate, 'MM/DD/YYYY')
    },
    async selectAsset (asset) {
      this.currentAsset = asset

      if (asset.extension) {
        switch (asset.extension) {
          case 'mp4':
          case 'qt':
          case 'mov':
          case 'wmv':
            return
          case 'embedded':
            this.updateAsset(asset, 'isComplete', true)
            await this.$logMedia('asset opened', asset.uid, 0, true)
            return
          default:
            // log media logger for opened
            window.open(asset.url)
            this.updateAsset(asset, 'isComplete', true)
            await this.$logMedia('asset opened', asset.uid, 0, true)
            break
        }
      } else if (asset.test) {
        // it is a quiz
        this.openQuiz(asset)
      } else {
        // no idea what type of asset they are trying to open. Probably bad data
        this.$failureMsg(`Sorry, can't open the selected asset`)
      }
    },
    updateAsset (asset, property, value) {
      let index = this.moduleAssets.findIndex(moduleAsset => moduleAsset.uid === asset.uid)

      if (index > -1) {
        this.moduleAssets[index][property] = value

        // unlock the next asset
        if (property === 'isComplete') {
          let nextAsset = this.moduleAssets[index + 1]
          if (nextAsset) {
            this.updateAsset(nextAsset, 'isLocked', false)
          }
        }
      }
    },
    completeModule () {
      this.$store.dispatch('modules/completeModule', this.$route.params.moduleId).then(response => {
        this.moduleIsComplete = true
      })
      if (this.module.assigned && this.module.assignment && !this.module.assignment.completed_at) {
        this.$store.dispatch('assignments/completedByModuleUid', this.$route.params.moduleId).then(response => {
          this.$successMsg('Successfully completed the assignment')
        })
      }

      // might want to combine messages
    },
    reset () {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    async init () {
      this.$q.loading.show()
      this.loadingVideo = true

      let moduleInfo

      // getting basic info from algolia
      this.$queryAlgoliaModules(this.$route.params.moduleId).then(res => {
        moduleInfo = res.results[0].hits[0]
        moduleInfo.settings = JSON.parse(moduleInfo.settings)
      })

      try {
        await this.$axios.get(this.$consts.GET_MODULE_URL + '/' + this.$route.params.moduleId).then((response) => {
          // this response should give back a flag if the module has been completed.
          // if it's completed we need to check if it's assigned, if so and the last_update_date on the completion is older than the assignment date, then don't mark it as completed
          let module = response
          Object.assign(module, moduleInfo)
          this.module = module
          this.currentAsset = this.module.media[0]
        })
      } catch (e) {
        this.$failureMsg()
      }
      this.$q.loading.hide()
      if ((this.module.hasCompleted && !this.module.assigned) || (this.module.assigned && this.module.assignment && this.module.assignment.completed_at)) {
        this.moduleIsComplete = true
      }
      this.getModuleAssets()

      // if it's not a video, don't open first asset on load. Request from members.
      if (['mp4', 'qt', 'mov'].includes(this.moduleAssets[0].extension)) {
        this.selectAsset(this.moduleAssets[0])
      }

      // checking if module quiz (last asset for module) has been previously completed
      if (this.module.quiz_results && this.module.quiz_results.passed && this.happenedAfterAssignment(this.module.quiz_results.created_at)) {
        this.updateAsset(this.moduleAssets[this.moduleAssets.length - 1], 'isComplete', true)
      }
    },
    happenedAfterAssignment (checkDate) {
      try {
        let assignmentDate = Date.parse(this.module.assignment.created_at)
        return Date.parse(checkDate) > assignmentDate
      } catch (e) {
        // if there isn't an assignment for the module we will end up here so return true
        return true
      }
    },
    getModuleAssets () {
      this.moduleAssets = this.module.media

      // attaching quizzes
      if (this.module.quizzes) {
        this.module.quizzes.forEach(quiz => {
          let matchingVideo = this.moduleAssets.find(video => video.uid === quiz.selectedMedia)
          let matchingVideoIndex = this.moduleAssets.indexOf(matchingVideo)
          if (matchingVideoIndex > -1) {
            this.moduleAssets.splice(matchingVideoIndex + 1, 0, quiz)
          } else if (quiz.selectedMedia === 'Module') {
            this.moduleAssets.push(quiz)
          }
        })
      }

      // setting extra default properties
      this.moduleAssets.forEach((asset, index) => {
        Vue.set(asset, 'isDownloadDisabled', true)
        this.canDownload(asset)
        Vue.set(asset, 'isComplete', false)
        Vue.set(asset, 'isLocked', true)
        Vue.set(asset, 'adminMode', false)
      })
      this.assetsAreReady = true
    },
    openQuiz (quizAsset) {
      this.quizState = 'preview'
      this.quizStep = 1
      // this.loadedQuiz.assetIndex = assetIndex
      this.loadedQuiz.currentQuestionIndex = 0

      this.loadedQuiz = quizAsset

      setTimeout(() => {
        this.takeQuiz = true
      }, 100)
    },
    downloadCert () {
      var doc = new JsPDF('l')

      let certImage = document.createElement('img')
      certImage.id = 'myCertImage'
      certImage.src = `/statics/certificates/cert-${this.module.settings.certificate}.jpg`
      certImage.display = 'none'
      document.body.appendChild(certImage)
      doc.addImage(document.getElementById('myCertImage'), 'JPEG', 0, 0)

      doc.addFileToVFS('MinionPro-Regular.ttf', MinionPro)
      doc.addFont('MinionPro-Regular.ttf', 'MinionPro', 'normal')

      doc.addFileToVFS('AvenirNext-Regular.ttf', AvenirNext)
      doc.addFont('AvenirNext-Regular.ttf', 'AvenirNext', 'normal')

      doc.setFont('MinionPro')

      let textColor = '#000000'
      switch (this.module.settings.certificate) {
        case '1':
          textColor = '#BF311A'
          break
        case '2':
          textColor = '#5A481C'
          break
        case '3':
          textColor = '#933C06'
          break
        default:
          textColor = '#000000'
          break
      }
      doc.setTextColor(textColor)
      doc.setFontSize(46)
      doc.setFontStyle('normal')
      doc.text(this.$store.getters['auth/user'].first_name + ' ' + this.$store.getters['auth/user'].last_name, 150, 98, null, null, 'center')

      doc.setFontSize(18)

      let completedDate = this.module.assigned ? (this.module.assignment.completed_at || Date.now()) : (this.module.firstCompletedDate || Date.now())
      doc.text('for successfully completing the "' + this.module.title + '" module.', 150, 120, null, null, 'center')
      doc.text('Completed on ' + this.formatDate(completedDate), 150, 180, null, null, 'center')

      doc.save(`${this.module.title}-certificate.pdf`)
      document.body.removeChild(certImage)
    },
    async canDownload (asset) {
      if (asset.extension === 'mp4' || asset.extension === 'qt' || asset.extension === 'mov') {
        let strippedId = asset.url.split('/')[4].split('.')[0]
        let url = `${this.$consts.LMS_VIDEO_URL}asset-${asset.asset_uid}/${strippedId}_manifest.xml${this.$consts.SAS_STRING}`

        if (window.fetch) {
          try {
            let res = await fetch(url)
            if (res.status === 200) {
              asset.isDownloadDisabled = false
            }
          } catch (e) {
            asset.isDownloadDisabled = true
          }
        }
        // fetch(url).then(res => {
        //   if (res.status === 200) {
        //     asset.isDownloadDisabled = false
        //   }
        // })
      }
    }
  },
  async created () {
    this.$axios.get(this.$consts.GET_IP_ACCESS_URL).then((response) => {
      this.accessList = response.data
    }).catch((error) => {
      throw error
    })

    this.init()
  },
  watch: {
    '$route.params.moduleId': function () {
      this.reset()
      this.init()
    },
    adminMode (newVal) {
      this.moduleAssets.forEach(asset => {
        this.updateAsset(asset, 'adminMode', newVal)
      })
    }
  }
}
</script>
