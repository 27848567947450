<template>
  <div class="row full-width">
    <modules-list :modules="modules" title="See Similar Videos" bookmarkable="false"></modules-list>
    <div class="row full-width justify-center q-mt-lg">
      <q-btn flat dense @click="$router.push({ name: 'browse', params: { categoryUid: module.categories[0] } }).catch(() => {})">See All in this category</q-btn>
    </div>
  </div>
</template>

<script>
import ModulesList from 'components/ModulesList'

export default {
  name: 'SimilarVideos',
  components: { ModulesList },
  props: {
    module: {},
    categoryUid: {}
  },
  data () {
    return {
      numberOfSimilarVideos: 3,
      modules: []
    }
  },
  methods: {
    async getModules () {
      this.$moduleSearch.clearCache()
      await this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX + '_created_desc',
          query: '',
          params: {
            hitsPerPage: this.numberOfSimilarVideos,
            filters: 'customer_uid:' + this.$store.getters['auth/company'].uid,
            facetFilters: [
              `categories:${this.module.categories[0]}`,
              'browsable:true'
            ]
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.modules = content.results['0'].hits
      })
    }
  },
  created () {
    this.getModules()
  }
}
</script>

<style>

</style>
