<template>
  <q-responsive :ratio="16/9" class="row full-width">
    <div class="row full-width">
      <video id="video1" width="100%" height="100%" crossorigin="anonymous" controls :class="loadingVideo ? 'loadingVideo' : ''" class="azuremediaplayer amp-default-skin amp-big-play-centered" tabindex="0" data-setup='{ "controls": true, "autoplay": false }'></video>
    </div>
  </q-responsive>
</template>

<script>

export default {
  name: 'VideoPlayer',
  props: {
    asset: {},
    module: {}
  },
  data () {
    return {
      firstPlay: true,
      maxTime: 0,
      azurePlayer: null,
      loadingVideo: false,
      canLogPause: true
    }
  },
  watch: {
    '$q.appVisible' (appInView) {
      if (!appInView && this.azurePlayer) {
        this.azurePlayer.pause()
      }
    }
  },
  methods: {
    loadAmpVideo (asset) {
      this.maxTime = 0
      // let videoAsset = this.moduleAssets[index || this.currentAsset]
      // let videoAsset = asset
      let videoAsset = this.asset
      this.azurePlayer = amp('video1', {
        autoplay: false,
        controls: true,
        logo: { enabled: false },
        src: ''
      })
      this.loadingVideo = true
      this.azurePlayer.src([{ src: (videoAsset.url !== undefined) ? videoAsset.url : videoAsset.location_url, type: videoAsset.type }])
      this.loadingVideo = false

      // if they have a previous log that is not finished, start them at the time they left off at
      if (videoAsset.last_log && !videoAsset.last_log.is_finished && this.happenedAfterAssignment(videoAsset.last_log.updated_at)) {
        this.maxTime = parseInt(videoAsset.last_log.pause_time)
      }

      this.firstPlay = true
      this.addAmpListener()
    },
    addAmpListener () {
      // amp video listeners
      this.azurePlayer.addEventListener('playing', () => {
        if (this.firstPlay) {
          this.firstPlay = false
          if (this.maxTime !== 0) {
            this.azurePlayer.currentTime(this.maxTime)
          }
        }
      })

      // update time watched
      this.azurePlayer.addEventListener('timeupdate', () => {
        if (!this.azurePlayer) {
          try { this.azurePlayer.removeEventListener('timeupdate') } catch (err) {}
          return
        }

        if (!this.azurePlayer.seeking() && this.azurePlayer.currentTime() > this.maxTime) {
          this.maxTime = this.azurePlayer.currentTime()
        }
      })

      // prevent user from seeking
      // if (this.module.settings.restrictProgression && !this.asset.isComplete) {
      this.azurePlayer.addEventListener('seeking', (e) => {
        if (!this.azurePlayer) {
          try { this.azurePlayer.removeEventListener('seeking') } catch (err) {}
          return
        }
        if (this.asset.adminMode) return
        if (this.module.settings.restrictProgression && !this.asset.isComplete && this.azurePlayer.currentTime() > this.maxTime) {
          this.azurePlayer.currentTime(this.maxTime)
        }
      })
      // }

      this.azurePlayer.addEventListener('pause', async () => {
        if (!this.azurePlayer) {
          try { this.azurePlayer.removeEventListener('pause') } catch (err) {}
          return
        }
        if (this.azurePlayer.ended()) {
          if (this.canLogPause) {
            // Azure player fires the pause event off twice which would cause two entries into our db
            // having this flag, we can delay the second call by 1000ms. Can increase it later.
            this.canLogPause = false
            setTimeout(() => {
              this.canLogPause = true
            }, 1000)

            let video = this.asset

            await this.$logMedia('video ended', video.uid, Math.ceil(parseInt(this.azurePlayer.currentTime())), true)

            // this.completeAsset(this.currentAsset)
            this.$emit('updateAsset', this.asset, 'isComplete', true)

            // if (video.quizzes && video.quizzes.length > 0) {
            //   this.handleAssetClick(this.currentAsset + 1)
            // }
          }
        } else {
          if (this.canLogPause) {
            // Azure player fires the pause event off twice which would cause two entries into our db
            // having this flag, we can delay the second call by 1000ms. Can increase it later.
            this.canLogPause = false
            setTimeout(() => {
              this.canLogPause = true
            }, 1000)

            await this.$logMedia('video paused', this.asset.uid, Math.floor(this.maxTime), false)
          }
        }
      })
    },
    happenedAfterAssignment (checkDate) {
      try {
        let assignmentDate = Date.parse(this.module.assignment.created_at)
        return Date.parse(checkDate) > assignmentDate
      } catch (e) {
        // if there isn't an assignment for the module we will end up here so return true
        return true
      }
    }
  },
  mounted () {
    this.loadAmpVideo(this.asset)
  },
  beforeDestroy () {
    if (this.azurePlayer) {
      this.azurePlayer.dispose()
    }
  }
}
</script>

<style lang="stylus" scoped>
.loadingVideo
  width 100% !important
  height 500px !important
  background-color black
</style>
