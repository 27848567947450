<template>
  <q-item :active="activeUid === asset.uid" clickable @click="selectAsset(asset)" class="col-12 q-mt-sm" :disable="isLocked">
    <q-item-section avatar>
      <q-icon :name="icon" />
    </q-item-section>
    <q-item-section>
      <q-item-label>{{ asset.name ? asset.name.split('.')[0] : 'Quiz'}}</q-item-label>
      <q-item-label class="secondaryText-text" caption v-if="asset.extension === 'mp4' || asset.extension === 'qt' || asset.extension === 'mov'">Video</q-item-label>
      <q-item-label class="secondaryText-text" caption v-else>Click to Open</q-item-label>
    </q-item-section>
    <q-item-section avatar v-if="asset.isComplete">
      <q-icon name="check_circle" />
    </q-item-section>
    <q-item-section v-if="$isAllowed(['||', 'master-admin', 'site-admin'], true) && (asset.extension === 'mp4' || asset.extension === 'qt' || asset.extension === 'mov')" avatar>
      <q-btn :disable="asset.isDownloadDisabled" @click.prevent="downloadVideo(asset)" flat>
        <q-icon name="get_app" />
      </q-btn>
    </q-item-section>
    <q-item-section v-if="$isAllowed(['||', 'master-admin', 'site-admin'], true) && asset.test" avatar>
      <q-btn @click.prevent="downloadTest(asset)" flat>
        <q-icon name="get_app" />
      </q-btn>
    </q-item-section>
  </q-item>
</template>

<script>
import XLSX from 'xlsx'

export default {
  props: {
    asset: {},
    activeUid: {
      type: String,
      default: null
    },
    module: {}
  },
  computed: {
    icon () {
      let icon
      switch (this.asset.extension) {
        case 'mp4':
        case 'qt':
        case 'mov':
          icon = 'ondemand_video'
          break
        case 'pdf':
          icon = 'picture_as_pdf'
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
          icon = 'image'
          break
        case 'embedded':
          icon = 'code'
          break
        default:
          icon = 'school'
          break
      }
      return icon
    },
    isComplete () {
      return this.asset.isComplete
    },
    isLocked () {
      if (this.asset.adminMode) return false

      return (this.asset.isLocked !== undefined ? this.asset.isLocked : false)
    }
  },
  watch: {
    isComplete (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.completeAsset()
      }
    }
  },
  methods: {
    selectAsset (asset) {
      this.$emit('selectAsset', asset)
    },
    async downloadVideo (asset) {
      let strippedId = asset.url.split('/')[4].split('.')[0]
      let url = `${this.$consts.LMS_VIDEO_URL}asset-${asset.asset_uid}/${strippedId}_manifest.xml${this.$consts.SAS_STRING}`

      let res = await fetch(url)
      let xml = await res.text()

      let parser = new DOMParser()
      let xmlDoc = parser.parseFromString(xml, 'text/xml')
      let fileName = xmlDoc.getElementsByTagName('AssetFile')[0].getAttribute('Name')

      window.open(`${this.$consts.LMS_VIDEO_URL}asset-${asset.asset_uid}/${fileName}${this.$consts.SAS_STRING}`)
    },
    async downloadTest (asset) {
      let quiz = asset.test
      let quizArray = []

      quiz.forEach(question => {
        let row = {}
        row.question = question.content
        question.answers.forEach((answer, i) => {
          row[`answer${i + 1}`] = answer.answer
          row[`answer${i + 1}_isCorrect`] = answer.isChecked
          row[`answer${i + 1}_explanation`] = answer.explanation
        })
        quizArray.push(row)
      })

      let fileName = `${asset.selectedMedia === 'Module' ? this.module.title : this.module.media.find(media => media.uid === asset.selectedMedia).name}_quiz.xlsx`
      let workSheetName = 'Sheet 1'

      let wb = XLSX.utils.book_new()
      let ws = XLSX.utils.json_to_sheet(quizArray)
      XLSX.utils.book_append_sheet(wb, ws, workSheetName)
      XLSX.writeFile(wb, fileName)
    },
    happenedAfterAssignment (checkDate) {
      try {
        let assignmentDate = Date.parse(this.module.assignment.created_at)
        return Date.parse(checkDate) > assignmentDate
      } catch (e) {
        // if there isn't an assignment for the module we will end up here so return true
        return true
      }
    },
    completeAsset () {
      if (this.module.assigned && this.asset.last_finished_log && this.happenedAfterAssignment(this.asset.last_finished_log.updated_at)) {
        this.$emit('updateAsset', this.asset, 'isComplete', true)
        if (this.asset.quiz_results && this.asset.quiz_results.passed && this.happenedAfterAssignment(this.asset.quiz_results.updated_at)) {
          this.$emit('updateNextAsset', this.asset, 'isComplete', true)
        }
      } else if (!this.module.assigned && this.asset.last_finished_log) {
        this.$emit('updateAsset', this.asset, 'isComplete', true)
        if (this.asset.quiz_results && this.asset.quiz_results.passed) {
          this.$emit('updateNextAsset', this.asset, 'isComplete', true)
        }
      }
    }
  },
  created () {
    this.completeAsset()
  }
}
</script>
