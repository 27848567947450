<template>
    <q-card flat class="q-pa-lg bg-lmsBackground primaryText-text">
      <div class="row justify-end" v-if="quizState === 'preview' || quizState === 'results'">
        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </div>
      <q-card-section v-if="quizState === 'preview'">
        <div class="row justify-center q-my-md">
          <div class="text-h5">Quiz Preview</div>
        </div>
        <div class="row justify-center q-my-xl">
          <div class="col-12 text-h2 text-center text-weight-medium">{{ this.quiz.test.length }}</div>
          <div class="col-12 text-body2 text-center">Questions</div>
        </div>
        <div class="row justify-center q-my-xl">
          <div class="col-12 text-h2 text-center text-weight-medium">{{ this.quiz.settings.requiredPassingPercentage }}</div>
          <div class="col-12 text-body2 text-center">Score To Pass</div>
        </div>
        <div class="row justify-center q-my-xl" v-if="quiz.settings.tryAgainMethod !== '0'">
          <div class="col-12 text-h2 text-center text-weight-medium">{{ parseInt(this.quiz.settings.tryAgainMethod) - this.quiz.tally }}</div>
          <div class="col-12 text-body2 text-center">Chances Left To Pass</div>
        </div>
        <div class="row justify-center q-my-xl">
          <div class="col-12 row justify-center">
            <q-btn @click="beginQuiz" :disable="isQuizLocked() || quiz.test.length === 0" color="primary">Begin Quiz</q-btn>
          </div>
          <div v-if="quiz.test.length === 0" class="col-12 row justify-center q-my-lg">
            <div class="text-body2">There are no questions for this quiz. Please contact your site administrator to either remove the quiz or add questions to it.</div>
          </div>
          <div class="row">
            <div v-if="isQuizLocked()" class="text-h6">You have reached the max attempts. Please contact your supervisor.</div>
          </div>
        </div>
      </q-card-section>
      <q-card-section v-else-if="quizState === 'active'">
        <div class="text-h3 text-center">{{ quiz.test[currentQuestionIndex].content}}</div>
        <q-list class="column justify-center items-center q-my-lg q-gutter-md quiz-choices">
          <div class="text-caption text-center text-italic">*Questions can have multiple answers.</div>
          <q-item v-for="(answer, i) in quiz.test[currentQuestionIndex].answers" :key="i" :active="answer.userChecked" @click="selectAnswer(answer)" clickable class="text-center quiz-choice bg-lmsSidebar" active-class="selectedAnswer">
            <q-item-section>{{ answer.answer }}</q-item-section>
          </q-item>
          <q-btn @click="checkAnswer" color="primary">Submit Answer</q-btn>
        </q-list>
      </q-card-section>
      <q-card-section v-if="quizState === 'questionRecap'">
          <div class="text-h3 text-center">{{ questionResult }}</div>
          <div class="text-caption text-center text-italic">See correct answer and explanation below.</div>
        <q-list class="column justify-center items-center q-my-lg q-gutter-md quiz-choices">
          <div class="text-h6 text-center">{{ quiz.test[currentQuestionIndex].content }}</div>
          <q-item v-for="(answer, i) in quiz.test[currentQuestionIndex].answers" :key="i" :active="answer.isChecked" class="text-center quiz-choice-result" active-class="rightAnswer" :class="!answer.isChecked && answer.userChecked ? 'wrongAnswer' : ''">
            <q-item-section>
              <q-item-label>{{ answer.answer }}</q-item-label>
              <q-item-label caption>{{ answer.explanation }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-btn @click="handleNext" color="primary">{{ quiz.test.length - 1 === currentQuestionIndex ? 'Finish' : 'Next Question' }}</q-btn>
        </q-list>
      </q-card-section>
      <q-card-section v-if="quizState === 'results'">
        <div class="row justify-center">
          <div v-if="quizResults.passed">
            <div class="text-h4 text-center">Congratulations, you passed!</div>
          </div>
          <div v-else class="row justify-center">
            <div class="col-12 text-h4 text-center">Sorry, you didn't meet the required score</div>
            <q-btn @click="retryQuiz" class="q-my-lg" color="primary">Retry</q-btn>
          </div>
        </div>
      </q-card-section>
      <q-card-actions v-if="quizState === 'active' || quizState === 'questionRecap'">
        <q-linear-progress :value="quizProgress" style="position: absolute; bottom: 16px; width: 95%"/>
      </q-card-actions>
    </q-card>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'ModuleQuiz',
  props: {
    quiz: {},
    takeQuiz: {}
  },
  data () {
    return {
      currentQuestionIndex: 0,
      questionResult: null,
      quizProgress: 0,
      quizResults: {
        passed: null,
        percent: 0,
        pointsEarned: 0
      },
      quizState: 'preview'
    }
  },
  methods: {
    retryQuiz () {
      this.currentQuestionIndex = 0
      this.quizState = 'preview'
      this.quizProgress = 0
      this.quizResults = {
        passed: null,
        percent: 0,
        pointsEarned: 0
      }
    },
    beginQuiz () {
      this.quizState = 'active'
      this.quiz.test.forEach(question => {
        question.answers.forEach(answer => {
          Vue.set(answer, 'userChecked', false)
        })
      })
    },
    checkAnswer () {
      let question = this.quiz.test[this.currentQuestionIndex]
      let numWrong = 0

      question.answers.forEach(answer => {
        if ((answer.isChecked && !answer.userChecked) || (!answer.isChecked && answer.userChecked)) {
          // wrong answer
          numWrong++
        }
      })

      if (numWrong > 0) {
        this.questionResult = 'Incorrect'
      } else {
        this.questionResult = 'Correct!'
        this.quizResults.pointsEarned++
      }

      this.updateProgressBar()

      this.quizState = 'questionRecap'
    },
    handleNext () {
      if (this.quiz.test.length - 1 === this.currentQuestionIndex) {
        // finish the quiz
        this.submitQuiz()
      } else {
        this.quizState = 'active'
        this.currentQuestionIndex++
        this.updateProgressBar()
      }
    },
    isQuizLocked () {
      if (parseInt(this.quiz.settings.tryAgainMethod) === 0) {
        return false
      }

      return this.quiz ? this.quiz.tally >= parseInt(this.quiz.settings.tryAgainMethod) : true
    },
    selectAnswer (answer) {
      // check if question is single or multiple choice
      let question = this.quiz.test[this.currentQuestionIndex]
      let numOfCorrectAnswers = question.answers.filter(ans => ans.isChecked === true).length

      if (numOfCorrectAnswers > 1) {
        // multiple choice so select the answer
        answer.userChecked = !answer.userChecked
      } else {
        // single choice. Find previous selected answer and unselect it
        let previousAnswer = question.answers.find(ans => ans.userChecked === true)

        if (previousAnswer) {
          previousAnswer.userChecked = false
        }
        // now select the answer the user clicked
        answer.userChecked = !answer.userChecked
      }
    },
    submitQuiz () {
      this.quizResults.percent = this.quizResults.pointsEarned / this.quiz.test.length * 100
      this.quizResults.passed = this.quizResults.percent >= parseInt(this.quiz.settings.requiredPassingPercentage)

      let newResults = {
        quiz_uid: this.quiz.uid,
        passed: this.quizResults.passed,
        score: this.quizResults.percent
      }
      this.$q.loading.show({
        message: 'Submitting Quiz'
      })

      this.$axios.post(this.$consts.POST_QUIZ_RESULTS_URL, newResults).then((response) => {
        this.$successMsg('Your score has been posted')

        this.quiz.tally++

        this.$q.loading.hide()
        this.quizState = 'results'

        if (this.quizResults.passed) {
          // emitting event 'quizPassed' up to parent
          this.$emit('quizPassed', this.quiz, 'isComplete', true)
        }
      }).catch((e) => {
        this.$q.loading.hide()
        // need to figure out a way to handle this. Do we close the quiz or what?
        this.$failureMsg()
      })
    },
    updateProgressBar () {
      let progressUnit = (100 / (this.quiz.test.length * 2)) / 100
      this.quizProgress += progressUnit
    }
  }
}
</script>
