<template>
  <div class="row full-width">
    <div class="text-h6 col-12">Assets</div>
    <div class="row full-width" v-if="assets.length > 0">
      <q-list class="row full-width">
        <AssetListItem v-for="asset in assets" :key="asset.uid" :asset="asset" :activeUid="activeAssetUid" :module="module" @selectAsset="selectAsset" @updateAsset="updateAsset" @updateNextAsset="updateNextAsset" />
      </q-list>
    </div>
    <div class="row full-width justify-center" v-else>
      <div class="text-h5 text-italic">No Assets</div>
    </div>
  </div>
</template>

<script>
import AssetListItem from 'components/module/AssetListItem'

export default {
  name: 'AssetList',
  components: { AssetListItem },
  props: {
    assets: {
      type: Array,
      default: () => []
    },
    module: {}
  },
  data () {
    return {
      selectedAsset: null
    }
  },
  computed: {
    activeAssetUid () {
      return this.selectedAsset ? this.selectedAsset.uid : this.assets[0].uid
    },
    allAssetsComplete () {
      return this.assets.every(asset => asset.isComplete)
    }
  },
  watch: {
    assets (newVal) {
      if (newVal) {
        this.unlockAssets()
      }
    },
    allAssetsComplete (newVal) {
      if ((newVal && this.module.assigned && this.module.assignment.completed_at === null) || (newVal && !this.module.assigned && !this.module.hasCompleted)) {
        this.$emit('allAssetsComplete')
      }
    }
  },
  methods: {
    selectAsset (asset) {
      this.$emit('selectAsset', asset)
      this.selectedAsset = asset
    },
    unlockAssets () {
      this.assets.forEach((asset, i) => {
        // check if should be locked
        if (!this.module.settings.restrictProgression) {
          this.updateAsset(asset, 'isLocked', false)
        }

        if (i === 0) this.updateAsset(asset, 'isLocked', false)
      })
    },
    happenedAfterAssignment (checkDate) {
      try {
        let assignmentDate = Date.parse(this.module.assignment.created_at)
        return Date.parse(checkDate) > assignmentDate
      } catch (e) {
        // if there isn't an assignment for the module we will end up here so return true
        return true
      }
    },
    updateAsset (asset, property, value) {
      this.$emit('updateAsset', asset, property, value)
    },
    updateNextAsset (asset, property, value) {
      let index = this.assets.findIndex(moduleAsset => moduleAsset.uid === asset.uid)

      if (index > -1) {
        this.$emit('updateAsset', this.assets[index + 1], property, value)
      }
    }
  },
  created () {
    this.unlockAssets()

    const initialAllAssetsCompleted = this.assets.every(asset => asset.isComplete && asset.last_finished_log !== null && this.happenedAfterAssignment(asset.last_finished_log.created_at || null))

    if (this.module.assigned && initialAllAssetsCompleted && this.module.assignment.completed_at === null) {
      this.$emit('allAssetsComplete')
    }
  }
}
</script>
