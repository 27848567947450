<template>
  <div class="col-sm-8 col-xs-12">
    <q-list>
      <div v-for="(comment, i) in discussions" :key="comment.uid">
        <q-item class="q-py-lg q-mb-md">
          <q-item-section avatar class="col-1 items-center" top>
            <q-avatar size="42px">
              <q-img :src="comment.author.avatar || $consts.DEFAULT_AVATAR" style="height: 42px; width: 42px; border-radius: 25px;"/>
            </q-avatar>
          </q-item-section>
          <q-item-section top>
            <q-item-label caption class="secondaryText-text">{{ comment.author.name }}</q-item-label>
            <q-item-label>
              <div class="text-body1 text-weight-bold q-my-xs">{{ comment.body }}</div>
            </q-item-label>
            <q-item-label caption>
              <div class="row">
                <q-btn @click="showReply(comment)" top="false" flat dense><div class="text-caption secondaryText-text">Reply</div></q-btn>
                <q-btn v-if="comment.replies && comment.replies.length > 0" flat dense @click="toggleShowReplies(comment)" class="q-ml-lg"><div class="text-caption secondaryText-text">{{ comment.showReplies ? 'Hide' : 'Show' }} {{ comment.replies.length }} {{ comment.replies.length > 1 ? 'Replies' : 'Reply' }}</div></q-btn>
              </div>
            </q-item-label>
            <q-item-label v-if="comment.showReply">
              <div class="q-pa-lg">
                <q-input v-model="comment.newReplyText" label="Reply to Comment" filled autogrow />
                <q-btn @click="addReply(comment)" class="q-my-md" :loading="addingReplyComment" :disable="!comment.newReplyText" color="primary">Add Comment</q-btn>
                <q-btn @click="comment.showReply = false" flat >Cancel</q-btn>
              </div>
            </q-item-label>
          </q-item-section>
          <q-item-section top side>
            <slot name="topRightAction">
              <q-btn v-if="userCanDelete(comment.author.uid)" dense round flat icon="delete" @click.stop="deleteComment(comment, i)" />
            </slot>
          </q-item-section>
        </q-item>

        <!-- replies -->
        <div v-if="comment.replies && comment.replies.length > 0">
          <div v-if="comment.showReplies">
            <q-item v-for="(reply, i) in comment.replies" :key="reply.uid" :inset-level="1.5" class="q-py-lg">
              <q-item-section avatar class="col-1 items-center">
                <q-avatar size="42px">
                  <q-img :src="reply.author.avatar || $consts.DEFAULT_AVATAR" style="height: 42px; width: 42px; border-radius: 25px;"/>
                </q-avatar>
              </q-item-section>
              <q-item-section top>
                <q-item-label class="q-mt-sm" caption>{{ reply.author.name }}</q-item-label>
                <q-item-label>
                  <div class="text-body1 text-weight-bold q-my-xs">{{ reply.body }}</div>
                </q-item-label>
              </q-item-section>
              <q-item-section top side>
                <slot name="topRightAction">
                  <q-btn v-if="userCanDelete(reply.author.uid)" dense round flat icon="delete" @click.stop="deleteReply(comment, reply, i)" />
                </slot>
              </q-item-section>
            </q-item>
          </div>
        </div>

        <!-- <q-separator /> -->
      </div>
    </q-list>
    <div class="q-pa-lg" v-if="showDiscussion">
      <q-input v-model="newDiscussionText" label="Add a New Comment" class="bg-lmsSideBar primary-text" filled autogrow />
      <q-btn @click="addComment(newDiscussionText)" class="q-my-md" :loading="addingNewComment" :disable="!newDiscussionText" color="primary">Add Comment</q-btn>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ModuleComments',
  props: {
    moduleUid: {},
    discussions: {}
  },
  data () {
    return {
      showDiscussion: true,
      newDiscussionText: '',
      addingNewComment: false,
      addingReplyComment: false
    }
  },
  methods: {
    async addComment (body) {
      let comment = {
        moduleId: this.moduleUid,
        body: body,
        is_notify: false
      }

      this.addingNewComment = true

      try {
        let res = await this.$axios.post(this.$consts.ADD_COMMENT_URL + '/' + comment.moduleId, comment)

        this.addingNewComment = false

        this.discussions.push({ ...res })

        this.newDiscussionText = ''
      } catch (e) {
        this.addingNewComment = false
        this.$failureMsg()
      }
    },
    showReply (comment) {
      if (!comment.showReply) {
        Vue.set(comment, 'showReply', true)
        Vue.set(comment, 'newReplyText', '')
      }
    },
    async addReply (comment) {
      let reply = {
        commentUid: comment.uid,
        body: comment.newReplyText,
        is_notify: false
      }

      this.addingReplyComment = true

      try {
        let res = await this.$axios.post(this.$consts.ADD_REPLY_URL + '/' + reply.commentUid, reply)
        this.addingReplyComment = false
        comment.replies.push({ ...res })
        comment.newReplyText = ''
        this.toggleShowReplies(comment)
      } catch (e) {
        this.addingReplyComment = false

        this.$failureMsg()
      }

      comment.showReply = false
    },
    toggleShowReplies (comment) {
      if (comment.showReplies === undefined) {
        Vue.set(comment, 'showReplies', true)
      } else {
        comment.showReplies = !comment.showReplies
      }
    },
    async deleteComment (comment, i) {
      try {
        await this.$axios.delete(this.$consts.DELETE_COMMENT_URL + '/' + comment.uid)

        this.discussions.splice(i, 1)

        this.$successMsg('Deleted Comment')
      } catch (e) {
        this.$failureMsg('Something went wrong. Please try again.')
      }
    },
    async deleteReply (comment, reply, i) {
      try {
        await this.$axios.delete(this.$consts.DELETE_REPLY_URL + '/' + reply.uid)

        comment.replies.splice(i, 1)

        this.$successMsg('Deleted Reply')
      } catch (e) {
        this.$failureMsg('Something went wrong. Please try again.')
      }
    },
    userCanDelete (author) {
      return this.$store.getters['auth/userUid'] === author || this.$isAllowed(['manage-modules'])
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
